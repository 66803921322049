import api from "./http";

class UserService {
  static getUserCompanies(userId) {
    return api.get(`/user/${userId}/get-companies`);
  }

  static getUserCompany(userId) {
    return api.get(`/profile/user/${userId}/get-company`);
  }

  static updateCompany(userId, companyId, data) {
    return api.post(`/profile/user/${userId}/company/${companyId}/update`, data);
  }

  static updateGroup(userId, companyId, data) {
    return api.post(`/profile/user/${userId}/company/${companyId}/update-group`, data);
  }

  static updateConsultant(userId, data) {
    return api.post(`/profile/user/${userId}/consultant/update`, data);
  }

  static getConsultant(userId) {
    return api.get(`/profile/user/${userId}/get-consultant`);
  }

  static getUserConsultant(userId) {
    return api.get(`/profile/user/${userId}/get-consultant`);
  }

  static getRegisterStates() {
    return api.get(`/register/get-states`);
  }

  static getCompany(userId) {
    return api.get(`/profile/user/${userId}/get-company`);
  }

  static getProfile(userId) {
    return api.get(`/profile/user/${userId}/get-profile`);
  }

  static updateProfile(userId, data) {
    return api.post(`/profile/user/${userId}/update-user`, data);
  }

  static getGroupByCode(code, headers) {
    return api.get(`/group/${code}/group-by-code`, headers);
  }

  static addToGroup(companyId, groupId, data) {
    return api.post(`/my-company/${companyId}/grupo/${groupId}/add-to-group`, data);
  }

  static changePassword(data) {
    return api.post(`/auth/change-password`, data);
  }

  static sendPasswordResetLink(data) {
    return api.post(`/auth/send-password-reset-link`, data);
  }

  static notifyDiagnosis(companyId) {
    return api.get(`/landing/get-notifications/${companyId}`);
  }
  static deleteNotification(companyId, diagnosticId) {
    return api.delete(`/landing/delete-notification/${companyId}/${diagnosticId}`);
  }
}

export default UserService;
